.centerBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    .body {
      padding: 10px 20px;
      border: 1px solid grey;
      border-radius: 5px;
      width: 30%
    }
}