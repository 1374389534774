.innerContent {
    padding: 24px;
    background-color: white;
    height: 95%;
    margin: 10px 30px
}

.icon {
    font-size: 21px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
    color: white;
}

.icon:hover {
    color: #1890ff;
}

.logo {
    width: 93%;
    height: 63px
}

.logoContainer {
    background-color: white;
}

.email {
    color: white;
    float: right;
    font-size: 16px;
}