.inputStyle {
    margin-bottom: 10px;
}

.buttonStyle {
    margin-right: 10px;
    border-radius: 15px
}

.blockStyle {
    margin-top: 30px
}

.hoverRow {
    cursor: pointer;
}

.antdButtonThirdType {
    background-color: rgba(6, 19, 34, 0.9);
    color: white
}

.paginationStyle {
    margin: 10px 15px;
}

.infoStyle {
    color: dodgerblue;
    font-size: 17px;
    vertical-align: middle;
}

.leftSpace {
    margin-left: 10px;
}